
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Lato-Regular';
  src: local('Lato-Regular'), url(./assets/fonts/Lato-Regular.ttf) format("truetype");
}

@font-face {
  font-family: 'TWKLausanne-550';
  src: local('TWKLausanne-550'), url(./assets/fonts/TWKLausanne-550.ttf) format('truetype');
}

@font-face {
  font-family: 'TWKLausanne-400';
  src: local('TWKLausanne-400'), url(./assets/fonts/TWKLausanne-400.ttf) format('truetype');
}

@font-face {
  font-family: 'TWKLausanne-300';
  src: local('TWKLausanne-300'), url(./assets/fonts/TWKLausanne-300.ttf) format('truetype');
}
